import { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, notification } from 'antd';

import PersonalInfo from './PersonalInfo';

import "./EditLogisticsCustomer.css"
import { fetchCreate, fetchUpdate, fetchCustomerById } from "../../redux/logisticsCustomerSlice";
import { ExportOutlined } from '@ant-design/icons';

const EditLogisticsCustomer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [customer, setCustomer] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {

    if(id !== "create") {
      fetchCustomerById(id)
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((err) => console.log(err));
    }
    
  }, [id, refresh]);

  const handleSubmit = async () => {

    if(id === "create") {
      // create new customer
      fetchCreate(customer)
        .then((res) => {
          notification['success']({
            message: 'Logistics Customer created successfully',
          });
          navigate("/logistics-customers");
          setRefresh(r => !r)
        })
        .catch((err) => {
          console.log(err)
          notification['error']({
            message: 'Logistics Customer couldn\'t created successfully',
          });
          setRefresh(r => !r)
        });
    } else {
      // update customer 

      let updateCustomer = customer;

      fetchUpdate(updateCustomer._id, updateCustomer)
        .then((res) => {
          notification['success']({
            message: 'Logistics Customer saved successfully',
          });
          setRefresh(r => !r)
        })
        .catch((err) => {
          console.log(err)
          notification['error']({
            message: 'Logistics Customer couldn\'t saved successfully',
          });
          setRefresh(r => !r)
        });
    }

  }

  return (
    <div className="container">

      <div className="customer-details">
        <Form
          form={form}
          className="customer-form"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div className="header-save">
            <h2 className="customer-header">
              {customer?.firstName} {customer?.lastName} &nbsp;
              {customer?.customer && <ExportOutlined onClick={() => window.open(`../customers/${customer?.customer}`)}/>}
            </h2>
            <div className='header-buttons'>
              <Button type="primary" style={{ marginRight: "10px" }} onClick={handleSubmit}>
                Save
              </Button>
              <Button type="secondary" onClick={() => navigate('../logistics-customers')}>
                Cancel
              </Button>
            </div>
          </div>

          <PersonalInfo 
              customer={customer}
              setCustomer={setCustomer}
              id={id}
          />

        </Form>
      </div >
    </div >
  )
};

export default EditLogisticsCustomer;