import { createSlice } from '@reduxjs/toolkit';
import { ADMIN_ACTIVE_ORDER_STATUSES } from '../constants';

const fiterSlice = createSlice({
  name: 'filter',
  initialState: {
    cartFilter: {
      filter: {},
      page: 1,
      limit: 100,
      sort: -1,
    },
    favoriteFilter: {
      filter: {},
      page: 1,
      limit: 100,
      sort: -1,
    },
    customerFilter: {
      page: 1,
      limit: 100,
      sort: -1,
    },
    logisticsCustomerFilter: {
      page: 1,
      limit: 100,
      sort: -1,
    },
    additionalServiceFilter: {
      sort: {},
      filter: {},
    },
    orderFilter: {
      page: 1,
      limit: 10,
      sort: { checkout_date: -1 },
      filter: { status : ADMIN_ACTIVE_ORDER_STATUSES},
    },
    logisticsOrderFilter: {
      page: 1,
      limit: 100,
      sort: { createdAt: -1 },
    },
    orderStatusFilter: {
      page: 1,
      limit: 100,
      sort: { checkout_date: -1 },
      filter: {},
    },
    seriesFilter: {
      page: 1,
      limit: 100,
      filter: {},
    },
    creditStatusFilter: {
      page: 1,
      limit: 100,
      sort: { last_credit_payment_date: 1 },
      filter: {
        payment_type: 'Credit', status: { $nin: ['Cancelled', 'Created', 'Credit Pending'] }, credit_paid: false,
      },
    },
    invoiceFilter: {
      sort: {},
      filter: {},
      page: 1,
      limit: 100,
    },
    firstMileShipment: {
      page: 1,
      limit: 100,
      sort: -1,
    },
    error: null,
  },
  reducers: {
    setCartFilter: (state, action) => {
      state.cartFilter = action.payload;
    },
    setFavoriteFilter: (state, action) => {
      state.favoriteFilter = action.payload;
    },
    setCustomerFilter: (state, action) => {
      state.customerFilter = action.payload;
    },
    setLogisticsCustomerFilter: (state, action) => {
      state.logisticsCustomerFilter = action.payload;
    },
    setAddtionalServiceFilter: (state, action) => {
      state.additionalServiceFilter = action.payload;
    },
    setOrderFilter: (state, action) => {
      state.orderFilter = action.payload;
    },
    setLogisticsOrderFilter: (state, action) => {
      state.logisticsOrderFilter = action.payload;
    },
    setOrderStatusFilter: (state, action) => {
      state.orderStatusFilter = action.payload;
    },
    setSeriesFilter: (state, action) => {
      state.seriesFilter = action.payload;
    },
    setInvoiceFilter: (state, action) => {
      state.invoiceFilter = action.payload;
    },
    setCreditStatusFilter: (state, action) => {
      state.creditStatusFilter = action.payload;
    },
    resetOrderFilter: (state) => {
      state.orderFilter = {
        page: 1,
        limit: 10,
        sort: { checkout_date: -1 },
        filter: { status : ADMIN_ACTIVE_ORDER_STATUSES},
      };
    },
    resetOrderStatusFilter: (state) => {
      state.orderStatusFilter = {
        page: 1,
        limit: 10,
        sort: { checkout_date: -1 },
        filter: {},
      };
    },
    setFirstMileShipment: (state, action) => {
      state.firstMileShipment = action.payload;
    },
  },
});

export const {
  setCartFilter,
  setCustomerFilter,
  setAddtionalServiceFilter,
  setOrderFilter,
  setSeriesFilter,
  setInvoiceFilter,
  setCreditStatusFilter,
  resetOrderFilter,
  setFirstMileShipment,
  setFavoriteFilter,
  setOrderStatusFilter,
  resetOrderStatusFilter,
  setLogisticsCustomerFilter,
  setLogisticsOrderFilter
} = fiterSlice.actions;

export default fiterSlice.reducer;
