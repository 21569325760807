import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, notification, Select, InputNumber } from "antd";
import {
  fetchRefunds,
  fetchUpdate,
  fetchReasons,
  fetchOrderProductsForRefund,
  createRefund
} from "../../redux/refundSlice";
import { loncaRound } from "../../helpers";
import "./EditRefund.css";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { ProductForRefund, ExchangeOrderInfo } from "../../components";
import { fetchAllExchangeRates } from "../../redux/exchangeRatesSlice";
import { fetchCoupon, fetchCreateForRefund } from "../../redux/couponSlice";
import { fetchUpdate as fetchUpdateOrder, fetchCreateEnglishInvoice } from "../../redux/orderSlice";
import { ResponsiveImage } from "../../components";
import { REASONS_FOR_ITEM_COUNT_NOT_SERIES, LOCAL_VAT_RATE, NON_COUPON_REFUND_REASONS } from "../../constants";

const EditRefund = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const { id } = useParams();

  const { filter, refunds } = useSelector((state) => state.refunds);
  const { coupons } = useSelector((state) => state.coupon)
  const { user } = useSelector((state) => state.user);
  
  const { Option } = Select;
  
	const [form] = Form.useForm();
  const [orderForm] = Form.useForm();
  const [newProductForm] = Form.useForm();
	const [currencies, setCurrencies] = useState([]);
	const [currency, setCurrency] = useState();
  const [coupon, setCoupon] = useState({});
  const [giveCoupon, setGiveCoupon] = useState(false);

  const [addNewProduct, setAddNewProduct] = useState(false);
  const [newRefund, setNewRefund] = useState({});
  const [refundReasons, setRefundReasons] = useState([]);
  const [refundableProducts, setRefundableProducts] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [seriesCount, setSeriesCount] = useState(0);

  const [totalRefundAmount, setTotalRefundAmount] = useState(0);
  const [totalRefundAmountLocal, setTotalRefundAmountLocal] = useState(0);
  const [totalRefundAmountForCupon, setTotalRefundAmountForCoupon] = useState(0);
  const [totalRefundAmountLocalForCoupon, setTotalRefundAmountLocalForCoupon] = useState(0);
  const [refundAmounts, setRefundAmounts] = useState([]);
  const [orderCurrencySymbol, setOrderCurrencySymbol] = useState("$");
  const [changedRefunds, setChangedRefunds] = useState([]);
  const [orderDeliveryDate, setOrderDeliveryDate] = useState("");
  const [refundRequestDate, setRefundRequestDate] = useState("");
  const [orderRefunds, setOrderRefunds] = useState();
  const [orderRefundsOld, setOrderRefundsOld] = useState();
  const [customer, setCustomer] = useState({});

  const [amountReturnedLocal, setAmountReturnedLocal] = useState(0);
  const [couponPercentage, setCouponPercentage] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [discountForCoupon, setDiscountForCoupon] = useState(0);
  const [totalLocalVat, setTotalLocalVat] = useState(0);
  const [localVat, setLocalVat] = useState(0);
  const [localVatForCoupon, setLocalVatForCoupon] = useState(0);
  const [discountedReturnAmount, setDiscountedReturnAmount] = useState(0);
  const [discountedReturnAmountForCoupon, setDiscountedReturnAmountForCoupon] = useState(0);

  const updateRefundAmounts = (id, amount, localAmount) => {
    const existingIndex = refundAmounts.findIndex((data) => data.id === id);

    if (existingIndex !== -1) {
      const updatedData = [...refundAmounts];
      updatedData[existingIndex].amount = parseFloat(amount);
      updatedData[existingIndex].localAmount = parseFloat(localAmount);
      setRefundAmounts(updatedData);
    } else {
      setRefundAmounts((prevData) => [...prevData, { id, amount: parseFloat(amount), localAmount: parseFloat(localAmount) }]);
    }

  };

  const updateChangedRefunds = (id, updatedRefund, customer_name, unique_order_id) => {
    const existingIndex = changedRefunds.findIndex((data) => data.id === id);
    let newChangedRefunds;

    if (existingIndex !== -1) {
      const updatedData = [...changedRefunds];
      updatedData[existingIndex].updatedRefund = updatedRefund;
      updatedData[existingIndex].giveCoupon = giveCoupon;
      newChangedRefunds = updatedData;
      setChangedRefunds(updatedData);
    } else {
      newChangedRefunds = [...changedRefunds, { id, updatedRefund, giveCoupon }];
      setChangedRefunds((prevData) => [...prevData, { id, updatedRefund, giveCoupon }]);
    }


    if(orderRefundsOld?.order_refund_status === "Vendor Pending" && typeof updatedRefund?.vendor_status !== "undefined") {
      let hasAnyVendorPendingRefund = false;
      let changedFromVendorPending = false;
      
      for (const refund of orderRefunds?.refunds) {
        const correspondingChangedRefund = newChangedRefunds?.find(changed => changed?.id?.toString() === refund?._id?.toString())?.updatedRefund;
        const correspondingRefund = correspondingChangedRefund || refund;

        if (correspondingChangedRefund) {
          if (correspondingChangedRefund?.vendor_status !== "Vendor Pending" && refund?.vendor_status === "Vendor Pending") {
            changedFromVendorPending = true;
          }
        }

        if (correspondingRefund?.vendor_status === "Vendor Pending") {
          hasAnyVendorPendingRefund = true;
        }
      };

      if(!hasAnyVendorPendingRefund && changedFromVendorPending) {
        setOrderRefunds({...orderRefunds, order_refund_status: "Customer Pending"});
      }

      if (hasAnyVendorPendingRefund && orderRefunds?.vendor_status !== "Vendor Pending") {
        setOrderRefunds({...orderRefunds, order_refund_status: "Vendor Pending"});
      }
    }
  };

  const handleRefundProductChanges = async () => {
    const promises = changedRefunds.map(async (refund) => {
      const { id, updatedRefund, giveCoupon } = refund;
      
      try {
        await fetchUpdate(id, updatedRefund, { uniqueOrderId: orderRefunds?.unique_order_id, customerName: customer?.firstName + " " + customer?.lastName, source: "Edit Refund" });
        notification["success"]({
          message: `Refunds for order modified successfully`,
        });
      } catch (err) {
        console.error("Error updating refunds:", err);
        notification["error"]({
          message: err?.response?.data?.error || `Exchange for order couldn't be modified successfully`,
        });
      }
    });
  
    try {
      await Promise.all(promises);
  
    } catch (err) {
      console.error("Error updating refunds:", err);
      notification["error"]({
        message: err?.response?.data?.error || `Exchange for order couldn't be modified successfully`,
      });
    }
  };

  useEffect(() => {
    fetchReasons()
      .then(res => {
        if (res?.data) 
          setRefundReasons(res.data)
      })
  }, []);

  useEffect(() => {
    if(addNewProduct) {
      fetchOrderProductsForRefund(id)
        .then(res => {
          if(res?.data){
            setRefundableProducts(res?.data?.filter(i => i?.order_status !== "StockOut"));
          }
        }).catch((err) => {
          console.error(`Errror fetching products: ${err}`);
        });
    }
  }, [addNewProduct])
  
  const showItemCountNotSeries = () => {
    if (newRefund?.refund_reason && REASONS_FOR_ITEM_COUNT_NOT_SERIES?.includes(newRefund?.refund_reason)) {
      return true;
    }
  }
  
  useEffect(() => {
    const refundsRaw = orderRefunds?.refunds;
    let currTotalRefundAmount = 0;
    let currTotalRefundAmountLocal = 0;
    let currTotalRefundAmountForCoupon = 0;
    let currTotalRefundAmountLocalForCoupon = 0;
    
    refundsRaw?.map((refundRaw) => {
      const reasonForRefund = refundRaw?.refund_reason;
      const currentRefund = refundAmounts?.find(r => r?.id === refundRaw?._id);

      if(currentRefund) {
        if(NON_COUPON_REFUND_REASONS.includes(reasonForRefund)) {
          currTotalRefundAmount = currTotalRefundAmount + (currentRefund?.amount || 0);
          currTotalRefundAmountLocal = currTotalRefundAmountLocal + (currentRefund?.localAmount || 0);
        } else {
          currTotalRefundAmountForCoupon = currTotalRefundAmountForCoupon + (currentRefund?.amount || 0);
          currTotalRefundAmountLocalForCoupon = currTotalRefundAmountLocalForCoupon + (currentRefund?.localAmount || 0);
        }
      }
    });
    setTotalRefundAmount(currTotalRefundAmount);
    setTotalRefundAmountLocal(currTotalRefundAmountLocal);
    setTotalRefundAmountForCoupon(currTotalRefundAmountForCoupon);
    setTotalRefundAmountLocalForCoupon(currTotalRefundAmountLocalForCoupon);

  },[refundAmounts])

  useEffect(() => {
      setDiscount(loncaRound(totalRefundAmount * (couponPercentage)));
      setDiscountForCoupon(loncaRound(totalRefundAmountForCupon * (couponPercentage)));
      setTotalDiscount(loncaRound(totalRefundAmount * (couponPercentage)) + loncaRound(totalRefundAmountForCupon * (couponPercentage)))
  },[totalRefundAmount, totalRefundAmountForCupon, couponPercentage])

  useEffect(() => {
    if(orderRefunds?.local_vat > 0 || orderRefunds?.returned_local_vat > 0) {
      setLocalVat(loncaRound((totalRefundAmount - discount) * (orderRefunds?.local_vat_rate || LOCAL_VAT_RATE)))
    }
  },[totalRefundAmount, discount, orderRefunds?.local_vat_rate])

  useEffect(() => {
    if(orderRefunds?.local_vat > 0 || orderRefunds?.returned_local_vat > 0) {
      setLocalVatForCoupon(loncaRound((totalRefundAmountForCupon - discountForCoupon) * (orderRefunds?.local_vat_rate || LOCAL_VAT_RATE)))
    }
  },[totalRefundAmountForCupon, discountForCoupon, orderRefunds?.local_vat_rate])

  useEffect(() => {
    setTotalLocalVat(loncaRound(localVat + localVatForCoupon));
  }, [localVat, localVatForCoupon])

  useEffect(() => {
    setDiscountedReturnAmount(loncaRound(totalRefundAmount - discount + (orderRefunds?.returned_payment_cost || 0) + localVat))
  },[totalRefundAmount, discount, orderRefunds?.returned_payment_cost,  localVat])

  useEffect(() => {
    setDiscountedReturnAmountForCoupon(loncaRound(totalRefundAmountForCupon - discountForCoupon + localVatForCoupon))
  },[totalRefundAmountForCupon, discountForCoupon, localVatForCoupon])

  useEffect(() => {
    setOrderRefunds(refunds.find((a) => a?._id === id));
    setOrderRefundsOld(refunds.find((a) => a?._id === id));
  }, [refunds]);

  useEffect(() => {
    if (orderRefunds) {
      setCustomer(orderRefunds?.customer);
      setOrderDeliveryDate(new Date(orderRefunds?.order_delivery_date));
      setRefundRequestDate(new Date(orderRefunds?.refunds?.[0]?.createdAt));
      setAmountReturnedLocal(loncaRound(orderRefunds?.amount_returned * orderRefunds?.rate_usd_selling));
      orderForm.setFieldsValue({
        orderstatus: orderRefunds?.order_refund_status,
        incomingcargocompanyname: orderRefunds?.refund_cargo_info?.incoming_cargo,
        incomingtrackingnumber: orderRefunds?.refund_cargo_info?.incoming_tracking_number,
        outgoingcargocompanyname: orderRefunds?.refund_cargo_info?.outgoing_cargo,
        outgoingtrackingnumber: orderRefunds?.refund_cargo_info?.outgoing_tracking_number,
        givecoupon: giveCoupon,
        customerreturninvoice: orderRefunds?.customer_return_invoice,
        amountreturned: orderRefunds?.amount_returned,
        ordertotalreturnamount: orderRefunds?.returns,
        returnPaymentCost: orderRefunds?.returned_payment_cost,
        returnLocalVat: orderRefunds?.returned_local_vat,
      })
    }
    fetchAllExchangeRates()
		  .then(res => {
        setCurrencies(res?.data);
        setCurrency(res?.data?.find(c => (c?.abbreviation === coupon?.currency)) || res?.data?.find(c => (c?.abbreviation === "USD")))
        setOrderCurrencySymbol(res?.data?.find(c => (c?.abbreviation === orderRefunds?.orderCurrency))?.symbol || res?.data?.find(c => (c?.abbreviation === "USD"))?.symbol)
        })
  }, [orderRefunds]);

  useEffect(() => {
    if (!orderRefunds){
      dispatch(fetchRefunds({filter: { ...filter, filter : {order: id }}}));
    }
  }, [orderRefunds, dispatch, id]);

	useEffect(() => {
		fetchAllExchangeRates()
		  .then(res => {
			setCurrencies(res?.data);
			setCurrency(res?.data?.find(c => (c?.abbreviation === coupon?.currency)) || res?.data?.find(c => (c?.abbreviation === "USD")))
		  })
      form.setFieldsValue({
        discount_type: coupon?.discount_type,
        code: coupon?.code,
        description: coupon?.description,
        amount: coupon?.discount_amount,
        currency: coupon?.currency || "USD",
      });

      if (coupon?._id){
        setGiveCoupon(true)
      }
      
	}, [coupon])

  useEffect(() => {
    dispatch(fetchCoupon({ order: id }))
  }, [dispatch, id]);

  useEffect(() => {
    if(coupons?.length > 0){

      setCoupon(coupons[0])
    }
  }, [coupons]);

	const handleCouponSubmit = async () => {

    let updatedCoupon = { 
			...coupon, 
			discount_amount:  coupon.discount_amount,
      discount_type : "Absolute",
      return_discount: true,
      status: "Active",
      promotion: false,
      special_customer: orderRefunds?.customer?._id
		}
			
    fetchCreateForRefund(updatedCoupon, id)
      .then((res) => {
        notification['success']({
          message: 'Coupon created successfully',
        });
      })
      .catch((err) => {
        console.log(err)
        notification['error']({
          message: 'Coupon couldn\'t created successfully',
        });
      });
	}

  const handleAddNewProduct = async () => {

    newRefund.order = id;
    newRefund.customer = customer?._id;

    if(!newRefund.product){
      notification['error']({
        message: 'You should specify product',
      });
      return
    }
  
    if(!newRefund.refund_reason){
      notification['error']({
        message: 'You should specify refund reason',
      });
      return
    }
  
    if(showItemCountNotSeries()){
      if(!newRefund?.item_count){
        notification['error']({
          message: 'You should specify refund item count',
        });
        return
      } 
    } else {
      if(!newRefund?.series_amount){
        notification['error']({
          message: 'You should specify refund series amount',
        });
        return
      }
    } 

    
    createRefund(newRefund)
      .then((res) => {
        notification['success']({
          message: 'Created successfully',
        });

        dispatch(fetchRefunds({filter: { ...filter, filter : {order: id }}}));

        setNewRefund({})

        newProductForm.resetFields();

        setAddNewProduct(false)

      })
      .catch((err) => {
        console.log(err)
        notification['error']({
          message: 'Couldn\'t created successfully',
        });
      });
			
	}

  const handleAllRefundChanges= async ({ forceUpdate = false, conflictCheckOnly = true }) => {
    let isAnyError = false;
    changedRefunds?.map(changedRefund => {
      const isItemCount = changedRefund?.updatedRefund?.refund_reason && REASONS_FOR_ITEM_COUNT_NOT_SERIES?.includes(changedRefund?.updatedRefund?.refund_reason);

      if(isItemCount) {
        const itemNotes = changedRefund?.updatedRefund?.item_notes;
        const itemCount = Number(changedRefund?.updatedRefund?.item_count);
        const isValidToUpdate = itemCount === itemNotes?.length && !itemNotes?.some(itemNote => itemNote?.trim().length === 0);
        
        if(itemCount === 0) { 
          notification["error"]({
            message: "Item count cannot be 0!"
          });
          isAnyError = true;
        } else if(!isValidToUpdate) {
          notification["error"]({
            message: "Please fill in the item notes!"
          });
          isAnyError = true;
        }
      } else {
        const isValidToUpdate = !!changedRefund?.updatedRefund?.series_amount;
        if(!isValidToUpdate) {
          notification["error"]({
            message: "Please fill in the series amount"
          });
          isAnyError = true;
        }
      }
    });

    if(isAnyError) {
      return false; 
    }
    const isOrderStatusChanged = orderRefundsOld?.order_refund_status !== orderRefunds?.order_refund_status;
    const isCargoInfoChanged = orderRefundsOld?.refund_cargo_info !== orderRefunds?.refund_cargo_info;
    const isCustomerReturnInvoiceChanged = orderRefundsOld?.customer_return_invoice !== orderRefunds?.customer_return_invoice;
    const isAmountReturnedChanged = orderRefundsOld?.amount_returned !== orderRefunds?.amount_returned;
    
    if (changedRefunds?.length > 0) {
      await handleRefundProductChanges();

    }  

    if (giveCoupon && !coupon?._id) {

      await handleCouponSubmit();

    } 

    const orderUpdateCondition = isOrderStatusChanged || isCargoInfoChanged || isCustomerReturnInvoiceChanged || isAmountReturnedChanged || orderRefunds?.bank_info !== null;

    if (orderUpdateCondition) {

      let updatedOrder = {
        refund_status : orderRefunds?.order_refund_status,
        refund_cargo_info: orderRefunds?.refund_cargo_info,
        customer_return_invoice: orderRefunds?.customer_return_invoice,
        amount_returned: orderRefunds?.amount_returned,
        bank_info: orderRefunds?.bank_info
      }
  
      const changedCartItems = []
      const beforeUpdateItems = [];
      if (id) {
        fetchUpdateOrder(
          id,
          updatedOrder,
          changedCartItems,
          beforeUpdateItems,
          forceUpdate, 
          conflictCheckOnly
        )
        .then((res) => {
          notification['success']({
            message: 'Saved successfully',
          });
        })
        .catch((err) => {
          notification['error']({
            message: "Couldn't saved successfully",
          });
  
        });
      } 

    }
    return true;

  };

  return (
    <div className="refund-details">

      <div className="header-save">
          <h2 className="refund-header">Edit Return</h2>
          <div>
            {orderRefunds?.returns > 0 && <Button 
              type="primary"
              style={{ marginRight: "20px"}}
              onClick={() => {
                fetchCreateEnglishInvoice({
                  orders: [id],
                  mode: "return"
                })
                  .then(() => {
                    notification['success']({
                      message: t('fields.edit_order.Invoice created successfully'),
                    });
                  })
                  .catch(() => {
                    notification['error']({
                      message: t("fields.edit_order.Invoice couldn't created successfully"),
                    });
                  })
              }}>{t('fields.edit_order.Create Return Invoice')}
            </Button>}
            <Button
                type="primary"
                onClick={() => {
                  setAddNewProduct(true);
                }}

                style={{ marginRight: "20px"}}
              >
                {`Add New Product`}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleAllRefundChanges({ forceUpdate: true, conflictCheckOnly: false }).then((response) => {
                  if(response) {
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  }
                });;
              }}

              style={{ marginRight: "20px"}}
            >
              {`Save Changes`}
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                navigate("/refunds");
              }}
            >
              Cancel
            </Button>
          </div>
          
      </div>

      <ExchangeOrderInfo uniqueOrderId={orderRefunds?.unique_order_id} shipmentCountry={orderRefunds?.shipment_country} orderDeliveryDate={orderDeliveryDate} refundRequestDate={refundRequestDate} orderId={id}/>

      <div className="inline-inputs-refund">
        <label>
          Customer Name
          <Input value={customer?.firstName + " " + customer?.lastName} disabled />
        </label>

        <label>
          Customer Email
          <Input value={customer?.email} disabled />
        </label>

        <label>
          Customer Phone number
          <Input value={customer?.phone_number} disabled />
        </label>

      </div>
   
      <Form
          form={orderForm}
          layout="vertical"
        >
        <div className="inline-inputs-refund">
          <Form.Item name="incomingcargocompanyname" label="Incoming Cargo Company">
            <Input 
              value={orderRefunds?.refund_cargo_info?.incoming_cargo} 
              onChange={(e) => {
                setOrderRefunds(prevOrderRefunds => ({
                  ...prevOrderRefunds,
                  refund_cargo_info: {
                    ...prevOrderRefunds?.refund_cargo_info,
                    incoming_cargo: e.target.value
                  }
                }));
              }}   
            />
          </Form.Item>
          <Form.Item name="incomingtrackingnumber" label="Incoming Tracking Number">  
            <Input 
              value={orderRefunds?.refund_cargo_info?.incoming_tracking_number} 
              onChange={(e) => {
                setOrderRefunds(prevOrderRefunds => ({
                  ...prevOrderRefunds,
                  refund_cargo_info: {
                    ...prevOrderRefunds?.refund_cargo_info,
                    incoming_tracking_number: e.target.value
                  }
                }));
              }}   
            />
          </Form.Item> 

          <Form.Item name="outgoingcargocompanyname" label="Outgoing Cargo Company">
            <Input 
              value={orderRefunds?.refund_cargo_info?.outgoing_cargo} 
              onChange={(e) => {
                setOrderRefunds(prevOrderRefunds => ({
                  ...prevOrderRefunds,
                  refund_cargo_info: {
                    ...prevOrderRefunds?.refund_cargo_info,
                    outgoing_cargo: e.target.value
                  }
                }));
              }}   
            />
          </Form.Item>
          <Form.Item name="outgoingtrackingnumber" label="Outgoing Tracking Number">  
            <Input 
              value={orderRefunds?.refund_cargo_info?.outgoing_tracking_number} 
              onChange={(e) => {
                setOrderRefunds(prevOrderRefunds => ({
                  ...prevOrderRefunds,
                  refund_cargo_info: {
                    ...prevOrderRefunds?.refund_cargo_info,
                    outgoing_tracking_number: e.target.value
                  }
                }));
              }}   
            />
          </Form.Item> 
          

        </div>
        <div className="inline-inputs-refund-margin">
          <Form.Item name="orderstatus" label="Order Return Status">
            <Select defaultValue={orderRefunds?.order_refund_status} onChange={(e) => setOrderRefunds({...orderRefunds, order_refund_status: e})}>
              <Option value='Submitted'>Submitted</Option>
              <Option value="Customer Pending">Customer Pending</Option>
              <Option value="Vendor Pending">Vendor Pending</Option>
              <Option value="Operations Pending">Operations Pending</Option>
              <Option value='Disapproved'>Disapproved</Option>
              <Option value="Approved">Approved</Option>
              <Option value="Completed">Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item name="givecoupon" label="Give Coupon">
            <div></div>
            <Select value={giveCoupon} onChange={setGiveCoupon}
              disabled={coupon?._id}
            >
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>

        </div>

        <div className="inline-inputs-refund-margin">
          {couponPercentage > 0 && <Form.Item name="couponPercentage" label="Coupon Percentage"> 
          <div></div> 
            <Input 
              value={loncaRound(couponPercentage * 100) + "%"} 
              disabled 
            />
          </Form.Item>}
          <Form.Item name="totalrefundamount" label="Total Discount Amount">
            <div></div> 
            <Input 
              value={"$" + totalDiscount  + " - " + orderCurrencySymbol + loncaRound(totalDiscount * orderRefunds?.rate_usd_selling)} 
              disabled
            />
          </Form.Item>

          {/* {orderRefunds?.returned_local_vat > 0 && <Form.Item name="returnLocalVat" label="Order Return Local Vat"> 
          <div></div> 
            <Input 
              value={"$" + orderRefunds?.returned_local_vat + " - " + orderCurrencySymbol + loncaRound(orderRefunds?.returned_local_vat * orderRefunds?.rate_usd_selling)} 
              disabled 
            />
          </Form.Item>} */}

          <Form.Item name="discountedReturnLocalVat" label="Total Discounted Return Local Vat"> 
          <div></div> 
            <Input 
              value={"$" + totalLocalVat + " - " + orderCurrencySymbol + loncaRound(totalLocalVat * orderRefunds?.rate_usd_selling)} 
              disabled 
            />
          </Form.Item>
          {orderRefunds?.returns > 0 && <Form.Item name="ordertotalreturnamount" label="Order Total Return Amount"> 
          <div></div> 
            <Input 
              value={"$" + orderRefunds?.returns + " - " + orderCurrencySymbol + loncaRound(orderRefunds?.returns * orderRefunds?.rate_usd_selling)} 
              disabled 
            />
          </Form.Item>}
        </div>

        <div className="inline-inputs-refund">
          <Form.Item name="totalrefundamount" label="Total Product Amount(VAT) - Returns">
            <div></div> 
            <Input 
              value={"$" + totalRefundAmount?.toFixed(2) + " - " + orderCurrencySymbol + totalRefundAmountLocal?.toFixed(2)}
              disabled 
            />
          </Form.Item>

          <Form.Item name="totalrefundamount" label="Discount Amount - Returns">
            <div></div> 
            <Input 
              value={"$" + discount  + " - " + orderCurrencySymbol + loncaRound(discount * orderRefunds?.rate_usd_selling)} 
              disabled 
            />
          </Form.Item>
          
          <Form.Item name="discountedReturnLocalVat" label="Discounted Return Local Vat - Returns"> 
          <div></div> 
            <Input 
              value={"$" + localVat + " - " + orderCurrencySymbol + loncaRound(localVat * orderRefunds?.rate_usd_selling)} 
              disabled 
            />
          </Form.Item>
          {/* {orderRefunds?.returned_payment_cost > 0 && <Form.Item name="returnPaymentCost" label="Return Payment Cost"> 
          <div></div> 
            <Input 
              value={"$" + orderRefunds?.returned_payment_cost + " - " + orderCurrencySymbol + loncaRound(orderRefunds?.returned_payment_cost * orderRefunds?.rate_usd_selling)} 
              disabled 
            />
          </Form.Item>} */}
          
          <Form.Item name="ordertotalreturnamount" label="Net Return Amount - Returns"> 
            <div></div> 
              <Input 
                value={"$" + discountedReturnAmount + " - " + orderCurrencySymbol + loncaRound(discountedReturnAmount * orderRefunds?.rate_usd_selling)} 
                disabled 
              />
          </Form.Item>

        </div>
        <div className="inline-inputs-refund">
          <Form.Item name="totalrefundamountforcoupon" label="Total Product Amount(VAT) - Coupon">
            <div></div> 
            <Input 
              value={"$" + totalRefundAmountForCupon?.toFixed(2) + " - " + orderCurrencySymbol + totalRefundAmountLocalForCoupon?.toFixed(2)}
              disabled 
            />
          </Form.Item>

          <Form.Item name="totalrefundamount" label="Discount Amount - Coupon">
            <div></div> 
            <Input 
              value={"$" + discountForCoupon  + " - " + orderCurrencySymbol + loncaRound(discountForCoupon * orderRefunds?.rate_usd_selling)} 
              disabled 
            />
          </Form.Item>
          
          <Form.Item name="discountedReturnLocalVat" label="Discounted Return Local Vat - Coupon"> 
          <div></div> 
            <Input 
              value={"$" + localVatForCoupon + " - " + orderCurrencySymbol + loncaRound(localVatForCoupon * orderRefunds?.rate_usd_selling)} 
              disabled 
            />
          </Form.Item>

          <Form.Item name="ordertotalreturnamount" label="Net Return Amount - Coupon"> 
            <div></div> 
              <Input 
                value={"$" + discountedReturnAmountForCoupon + " - " + orderCurrencySymbol + loncaRound(discountedReturnAmountForCoupon * orderRefunds?.rate_usd_selling)} 
                disabled 
              />
          </Form.Item>
          

        </div>

        <div className="order-accounting">
          <span className="order-accounting-header">Accounting</span>

          <div className="order-accounting-inputs ">
            <Form.Item label="Amount Returned($)" name="amountreturned">
                <InputNumber 
                  className="order-accounting-return-amount"
                  min={0}
                  value={orderRefunds?.amount_returned}
                  onChange={(e) => {
                    setOrderRefunds({...orderRefunds, amount_returned: e});
                  }}
                />
            </Form.Item>

            <Form.Item label={`Amount Returned(${orderCurrencySymbol})`} name="amountreturnedlocal">
              <></>
                <InputNumber 
                  className="order-accounting-return-amount"
                  min={0}
                  onChange={(e) => {
                    setOrderRefunds({
                      ...orderRefunds,
                      amount_returned: loncaRound(e / orderRefunds?.rate_usd_selling)
                    })
                  }}
                  value={amountReturnedLocal}
                />
            </Form.Item>

            <Form.Item label="Customer Return Invoice" name="customerreturninvoice">
                <Select 
                  className="order-accounting-invoice"
                  defaultValue={orderRefunds?.customer_return_invoice}
                  onChange={(e) => setOrderRefunds({...orderRefunds, customer_return_invoice: e})}
                >
                    <Option value=''></Option>
                    <Option value='Refund Needed'>Invoice Needed</Option>
                    <Option value='Refund Not Needed'>Invoice Not Needed</Option>
                    <Option value='Refund Completed'>Invoice Completed</Option>   
                </Select>
            </Form.Item>
            
          </div> 
        </div>

        { (orderRefunds?.payment_type === "Bank" || orderRefunds?.payment_type === "Swift") &&
          <div className="order-accounting">
            <span className="order-accounting-header">Bank Information</span>

            <div className="order-accounting-inputs ">
              <div>
                <Form.Item label="Account / Receiver Name">
                  <Input
                    className="order-accounting-return-amount"
                    value={orderRefunds?.bank_info?.acount_name}
                    onChange={(e) => {
                      setOrderRefunds({ ...orderRefunds, bank_info: { ...orderRefunds?.bank_info, acount_name: e.target.value } });
                    }}
                  />
                </Form.Item>

                <Form.Item label="BIC / SWIFT Code">
                  <Input
                    className="order-accounting-return-amount"
                    value={orderRefunds?.bank_info?.bic_swift_code}
                    onChange={(e) => {
                      setOrderRefunds({ ...orderRefunds, bank_info: { ...orderRefunds?.bank_info, bic_swift_code: e.target.value } });
                    }}
                  />
                </Form.Item>
              </div>

              <div>
                <Form.Item label="IBAN">
                  <Input
                    className="order-accounting-return-amount"
                    value={orderRefunds?.bank_info?.iban}
                    onChange={(e) => {
                      setOrderRefunds({ ...orderRefunds, bank_info: { ...orderRefunds?.bank_info, iban: e.target.value } });
                    }}
                  />
                </Form.Item>

                <Form.Item label="Address">
                  <Input
                    className="order-accounting-return-amount"
                    value={orderRefunds?.bank_info?.address}
                    onChange={(e) => {
                      setOrderRefunds({ ...orderRefunds, bank_info: { ...orderRefunds?.bank_info, address: e.target.value } });
                    }}
                  />
                </Form.Item>
              </div>
              
            </div> 
          </div>
        }
      </Form>

      {coupon?._id || giveCoupon ? <div className="coupon-details">
				<Form
					form={form}
					className="coupon-form"
					layout="vertical"
          initialValues={{
						discount_type: coupon?.discount_type,
						code: coupon?.code,
						description: coupon?.description,
						currency: currency?.abbreviation ,
            amount: coupon?.discount_amount
					}}
 
				>
					<div className="header-save">
						<h2 className="coupon-header">Coupon Code: {coupon?.code ? coupon.code : ""}</h2>
					</div>
					<div className="inline-inputs-refund-margin">
            <Form.Item label="Code" name="code">
              <Input
                required 
                disabled={coupon?._id}
                value={coupon?.code} 
                onChange={(e) => {
                  setCoupon({...coupon, code: e.target.value.trim().replaceAll(" ", "").toUpperCase()})
                }}
              />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input 
                required
                disabled={coupon?._id}
                value={coupon?.description} 
                onChange={(e) => {
                  setCoupon({...coupon, description: e.target.value})
                }}
              />
            </Form.Item>
          </div>
          <div className="inline-inputs-refund-margin">

            <Form.Item label="Amount" name="amount">
              <InputNumber
                required
                disabled={coupon?._id}
                prefix={currency?.symbol}
                value={coupon?.discount_amount === 0 ? "" : coupon?.discount_amount} 
                onChange={(e) => {
                  setCoupon({
                    ...coupon, 
                    discount_amount: loncaRound(e)
                  })
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item name="currency" label="Currency">
              <Select
                disabled={coupon?._id}
                value={currency?.abbreviation}
                onChange={(e) => {
                  setCoupon({
                    ...coupon, 
                    currency: e
                  })
                  setCurrency(currencies?.find(c => c?.abbreviation === e))
                }}
              >
                {currencies?.map(c => c?.abbreviation)?.map(currency => (
                  <Option value={currency}>{currency}</Option>
                ))}
              </Select>
            </Form.Item> 


					</div>
				</Form>
			</div> : null}

      {addNewProduct ? 
        <div className="coupon-details">
          <Form
            form={newProductForm}
            layout="vertical"
          >
            <div className={`form-top-refund}`}>
              <div>       
              <div className="header-save">
                <h2 className="coupon-header">Add New Product</h2>
                <div>
                  <Button 
                    type="primary" 
                    onClick={() => handleAddNewProduct()} 
                    style={{ marginRight: "10px" }}>
                    Save
                  </Button>
                </div>
              </div>     
                <div className="inline-inputs-refund-margin">

                  <Form.Item label="Product" name="newproduct">
                      <Select
                        style={{ width: "100%" }}
                        className="custom-product-selector"
                        value={newRefund?.product}
                        onChange={(e) => {
                          const productId = e.split('-')[0];
                          const selectedIndex = refundableProducts.findIndex(item => item?.product === productId);
                          setNewRefund({...newRefund, product: productId, series : refundableProducts[selectedIndex]?.series});
                          setItemCount(refundableProducts[selectedIndex]?.item_count)
                          setSeriesCount(refundableProducts[selectedIndex]?.quantity)
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                          const children = option?.children?.props?.children;
                          const textChild = children?.find(c => c?.props?.className === "add-product-selection-name");
                          if (typeof textChild?.props?.children !== "string") return false;
                          return (textChild?.props?.children || "")?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
                        }}
                      >
                        {refundableProducts.sort((a, b) => a?.product_name?.localeCompare(b?.product_name)).map((item, index) => (
                          <Option value={item?.product + "-" + index}>
                            <div className="add-product-selection">
                              <ResponsiveImage height={100} src={item?.product_image} alt="product"/>
                              <span className="add-product-selection-name">{item?.product_name}</span>
                            </div>    
                          </Option>))}
                      </Select>
                  </Form.Item>

                  <div className="add-product-reasons">            
                    <Form.Item label="Reason" name="refundreason">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          value={newRefund?.refund_reason}
                          onChange={(e) => {
                            setNewRefund({...newRefund, refund_reason: e});
                          }}
                          disabled={!newRefund?.product}
                          placeholder={!newRefund?.product ? "Please Select Product" : ""}
                        >
                          {newRefund?.product && (
                            refundReasons.sort((a, b) => a.name.localeCompare(b.name)).map(reason => (
                              <Option key={reason?.name} value={reason?.name}>{reason?.name}</Option>
                            ))
                          )}

                          
                        </Select>
                    </Form.Item>

                    {showItemCountNotSeries() ?

                      <Form.Item label="Item Count" name="itemcount">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          disabled={!showItemCountNotSeries()}
                          value={newRefund?.item_count}
                          onChange={(e) => {
                            setNewRefund({...newRefund, item_count: e});
                          }}
                        >
                          {Array.from({length: itemCount * seriesCount}, (_, i) => i + 1).map(number => (
                          <Option value={`${number}`}>{number}</Option>))}
                        </Select>
                      </Form.Item>
                      :
                      <Form.Item label="Series Amount" name="seriesamount">
                        <Select
                          style={{
                            width: "100%",
                          }}
                          disabled={showItemCountNotSeries()}
                          value={newRefund?.series_amount}
                          onChange={(e) => {
                            setNewRefund({...newRefund, series_amount: e});
                          }}
                        >asdsada

                      {Array.from({length: seriesCount}, (_, i) => i + 1).map(number => (
                          <Option value={`${number}`}>{number}</Option>))}
                        </Select>
                      </Form.Item>
                  
                  
                    } 
                  </div>   
                </div>        
              </div>
            </div>
          </Form>
			  </div> : null}

      {orderRefunds?.refunds.map((refund) => (
        <ProductForRefund key={refund?._id} id={refund?._id} updateRefundAmounts={updateRefundAmounts} updateChangedRefunds={updateChangedRefunds} refundReasons={refundReasons} currencies={currencies} page={"edit-exchange"} setCouponPercentage={setCouponPercentage}/>
      ))}
    </div>
  );
};

export default EditRefund;
