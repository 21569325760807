import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select } from 'antd';
import  { fetchCountries }  from "../../redux/countrySlice";
import { useTranslation } from "react-i18next";
import "./EditLogisticsCustomer.css";
import { fetchCustomers } from "../../redux/customerSlice";
import { fetchSearchBarResults, searchBarItemsSelector } from "../../redux/searchSlice";
import { debounce } from "lodash";

const PersonalInfo = ({ customer, setCustomer }) => {
  const { Option } = Select;
  const { t } = useTranslation('translation');
  const [countryList, setCountryList] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [localSearchText, setLocalSearchText] = useState('');
  const searchBarItems = useSelector(searchBarItemsSelector);
	const searchType = "customer"
	const dispatch = useDispatch();

  const debouncedFetchResults = useCallback(
		debounce((searchText) => {
		  if (searchText) dispatch(fetchSearchBarResults({ searchText, searchType }));
		}, 200),
		[dispatch, searchType],
	  );

	useEffect(() => {
		if (localSearchText.length === 0) return;
		debouncedFetchResults(localSearchText);
	  }, [localSearchText, debouncedFetchResults]);
	  
	useEffect(() => {
		if (searchBarItems.length === 0) return;
		setCustomers(searchBarItems);
	  }, [searchBarItems]);

  useEffect(() => {
    fetchCountries()
      .then((res) => {
        setCountryList(res?.sort((a, b) => a?.name?.localeCompare(b?.name)));
      })
  }, []);

  useEffect(() => {
		if(customer?.customer){
			fetchCustomers('', 'all_list')
			.then(res => setCustomers(res?.data))
			.catch(err => console.log(err))
		}
	}, [])

  return (
    <div className="cart-form-top">
      <Form.Item label="Personal Details">
        <div className="box">
          <div className="inline_five_inputs">
            <Form.Item label="First Name">
              <Input
                value={customer?.firstName}
                onChange={(e) => setCustomer({ ...customer, firstName: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Last Name">
              <Input
                value={customer?.lastName}
                onChange={(e) => setCustomer({ ...customer, lastName: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Email">
              <Input
                value={customer?.email}
                onChange={(e) => setCustomer({ ...customer, email: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Phone Number">
              <Input
                value={customer?.phone_number}
                onChange={(e) => setCustomer({ ...customer, phone_number: e.target.value })}
              />
            </Form.Item>

            <Form.Item label="Unique Customer ID">
              <Input 
                value={customer?.unique_customer_id} 
                onChange={(e) => setCustomer({ ...customer, unique_customer_id: e.target.value })}
              />
            </Form.Item> 

            <Form.Item label="Lonca Customer">
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  const optionValue = option.props.value;
                  const optionKey = option.props.key;
                  return (
                  optionValue?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                  optionKey?.indexOf(input.toLowerCase()) >= 0
                  );
                }}
                value={customer?.customer?._id}
                onSearch={(input) => {
                  // Handle search logic here
                  setLocalSearchText(input);
                }}
                onChange={(e) => {
                  setCustomer({
                    ...customer,
                    customer: e,
                  });
                }}
              >
                <Option key={'customer-select'} value="none">
                  None || Type to see customers
                </Option>
                {customers?.slice().sort((a, b) => a?.firstName?.localeCompare(b?.firstName)).map((loncaCustomer, index) => (
                  <Option key={loncaCustomer?.email} value={loncaCustomer?._id}>
                    {loncaCustomer?.firstName} {loncaCustomer?.lastName} {'-'} {loncaCustomer?.email}
                  </Option>
                  ))}
              </Select>

            </Form.Item>
          </div>
        </div>
      </Form.Item>
        
        <Form.Item label={t('fields.edit_order.Shipping Address')}>
          <div className='box'>
              <div className='inline_inputs'>
                <Form.Item label={t('fields.edit_order.Address')}>
                  <Input
                    value={customer?.address_information?.address}
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        address_information: {
                          ...customer?.address_information,
                          address: e.target.value
                        }
                      })
                    }}
                  />
                </Form.Item>

                <Form.Item label={t('fields.edit_order.apartment_suite')}>
                  <Input
                    value={customer?.address_information?.apartment_suite}
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        address_information: {
                          ...customer?.address_information,
                          apartment_suite: e.target.value
                        }
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item label={t('fields.edit_order.City')}>
                  <Input
                    value={customer?.address_information?.city}
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        address_information: {
                          ...customer?.address_information,
                          city: e.target.value
                        }
                      })
                    }}
                  />
                </Form.Item>

                <Form.Item label={t('fields.edit_order.State')}>
                  <Input
                    value={customer?.address_information?.state_or_province}
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        address_information: {
                          ...customer?.address_information,
                          state_or_province: e.target.value
                        }
                      })
                    }}
                  />
                </Form.Item>

                <Form.Item label={t('fields.edit_order.Postal Code')}>
                  <Input
                    value={customer?.address_information?.postal_code}
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        address_information: {
                          ...customer?.address_information,
                          postal_code: e.target.value
                        }
                      })
                    }}
                  />
                </Form.Item>

                <Form.Item label={t('fields.edit_order.Country')}>
                  <Select
                    showSearch
                    value={customer?.address_information?.country}
                    optionFilterProp="children"
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        address_information: {
                          ...customer?.address_information,
                          country: e
                        }
                      })
                    }}>
                    {countryList?.map((country) =>
                        <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                  </Select>
                </Form.Item>

                <Form.Item label={t('fields.edit_order.Phone Number')}>
                  <Input
                    value={customer?.address_information?.phone_number}
                    onChange={(e) => {
                      setCustomer({
                        ...customer,
                        address_information: {
                          ...customer?.address_information,
                          phone_number: e.target.value
                        }
                      })
                    }}
                  />
                </Form.Item>
              </div>
            </div>
                        
        </Form.Item> 

        <Form.Item label={t('fields.edit_order.Billing Address')}>
          <div className='box'>

                  <div className='inline_inputs'>
                    <Form.Item label={t('fields.edit_order.Billing Name')}>
                      <Input
                        value={customer?.billing_address_information?.billing_name}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_information: {
                              ...customer?.billing_address_information,
                              billing_name: e.target.value
                            }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Company Type')}>
                        <Select
                        value={customer?.billing_address_information?.company_type}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_information: {
                              ...customer?.billing_address_information,
                              company_type: e 
                            }
                          })
                        }}
                        >
                          <Option value="Individual">Individual</Option>
                          <Option value="Company">Company</Option>
                        </Select>
                    </Form.Item>
                    
                  </div>
                  <div className='inline_inputs'>
                    <Form.Item label={t('fields.edit_order.VAT Number')}>
                      <Input
                        value={customer?.billing_address_information?.corporate_identity_number}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_information: {
                              ...customer?.billing_address_information,
                              corporate_identity_number: e.target.value
                            }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Tax Office')}>
                      <Input
                        value={customer?.billing_address_information?.tax_office}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_information: {
                              ...customer?.billing_address_information,
                              tax_office: e.target.value
                            }
                          })
                        }}
                      />
                    </Form.Item>
                    
                  </div>
                  <div className='inline_inputs'>
                    <Form.Item label={t('fields.edit_order.Address')}>
                      <Input
                        value={customer?.billing_address_information?.address}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_information: {
                              ...customer?.billing_address_information,
                              address: e.target.value
                            }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.City')}>
                      <Input
                        value={customer?.billing_address_information?.city}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_information: {
                              ...customer?.billing_address_information,
                              city: e.target.value
                            }
                          })
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className='inline_inputs'>
                    <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        value={customer?.billing_address_information?.postal_code}
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_information: {
                              ...customer?.billing_address_information,
                              postal_code: e.target.value
                            }
                          })
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Country')}>
                      <Select
                        showSearch
                        value={customer?.billing_address_information?.country}
                        optionFilterProp="children"
                        onChange={(e) => {
                          setCustomer({
                            ...customer,
                            billing_address_information: {
                              ...customer?.billing_address_information,
                              country: e
                            }
                          })
                        }}>
                        {countryList?.map((country) =>
                            <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="inline-inputs">
                    <div className="checkbox-container">
                      <label>
                        <input
                            type="checkbox"
                            value={customer?.billing_address_information?.isVatValid}
                            checked={customer?.billing_address_information?.isVatValid}
                            onChange={(e) => {
                              setCustomer({
                                ...customer,
                                billing_address_information: {
                                  ...customer?.billing_address_information,
                                  isVatValid: e.target.checked
                                }
                              })
                            }}
                        />
                        Is Valid VAT
                      </label>
                    </div>
                  </div>

          </div>
        </Form.Item>

    </div>
  )
};

export default PersonalInfo 