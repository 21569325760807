import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, notification, Checkbox, DatePicker } from 'antd';
import FormItem from "antd/lib/form/FormItem";
import ButtonGroup from "antd/lib/button/button-group";
import moment from "moment";
import { CloseOutlined, DownloadOutlined, ExportOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import _ from 'underscore';
import "./EditLogisticsOrder.css"
import {
  fetchUpdate,
  fetchCreate,
  listOrders,
  fetchCreateEnglishInvoice,
  createShipment
} from "../../redux/logisticsOrderSlice";
import  { fetchCountries }  from "../../redux/countrySlice";
import { fetchAllExchangeRates } from "../../redux/exchangeRatesSlice";
import {
  loncaRound,
  loncaFormatDate,
} from "../../helpers";
import { DESI_CONSTANT } from "../../constants";
import { fetchUploadUrl } from "../../redux/productSlice";
import { fetchCustomers } from '../../redux/logisticsCustomerSlice';

const EditLogisticsOrder = () => {

  const { id } = useParams();
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const initialOrder = {
    status: "Received",
    calculated_shipment_cost: 0,
    local_vat: 0,
    product_cost: 0,
    full_volume: 0,
    product_paid_amount: 0,
    shipment_paid_amount: 0,
    vendors: [],
  }
  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [order, setOrder] = useState(initialOrder);
  const [refresh, setRefresh] = useState(false);
  const orderStatuses = ["Received", "Operations Pending", "Packaged", "Ready", "On the Road", "Delivery", "Completed", "Lost & Undelivered"];
  const [packages, setPackages] = useState([]);
  const [calculatedDesi, setCalculatedDesi] = useState(0);
  const [calculatedWeight, setCalculatedWeight] = useState(0);
  const [reviewedCount, setReviewedCount] = useState(0)
  const [orderCurrencySymbol, setOrderCurrencySymbol] = useState("$");
  const [saveLoading, setSaveLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [newVendor, setNewVendor] = useState({});
  const [isAddingVendor, setIsAddingVendor] = useState(false);
  const [newProduct, setNewProduct] = useState({});
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [needCalculatePrices, setNeedCalculatePrices] = useState(false);
  const [shipmentCreationLoading, setShipmentCreationLoading] = useState(false);

  const { allProductTypes } = useSelector((state) => state.productTypes);

  const handleAddVendor = () => {
    setOrder(prevOrder => ({
      ...prevOrder,
      vendors: [...prevOrder?.vendors, { ...newVendor }]
    }));
    
    setNewVendor({});
    setIsAddingVendor(false);
    setNeedCalculatePrices(true);
  };

  const handleAddProduct = () => {
    setOrder(prevOrder => ({
      ...prevOrder,
      cart_item: [...prevOrder?.cart_item, { ...newProduct }]
    }));
    
    setNewProduct({});
    setIsAddingProduct(false);
  };

  const handleVendorChange = (index, field, value) => {
    setOrder(prevOrder => {
      const updatedVendors = [...prevOrder?.vendors];
      updatedVendors[index] = {
        ...updatedVendors[index],
        [field]: value
      };
      return { ...prevOrder, vendors: updatedVendors };
    });
  };

  const handleCartItemChange = (index, field, value) => {
    setOrder(prevOrder => {
      const updatedCartItems = [...prevOrder?.cart_item];
      updatedCartItems[index] = {
        ...updatedCartItems[index],
        [field]: value
      };
      return { ...prevOrder, cart_item: updatedCartItems };
    });
  };

  const handleDeleteVendorItem = ({ vendorId }) => {
    const updatedVendors = order?.vendors?.filter((vendor) => vendor?._id?.toString() !== vendorId?.toString());
  
    setOrder((prevOrder) => ({
      ...prevOrder,
      vendors: updatedVendors
    }));

    setNeedCalculatePrices(true);
  };

  const handleDeleteCartItem = ({ cartItemId }) => {
    const updatedCartItems = order?.cart_item?.filter((item) => item?._id?.toString() !== cartItemId?.toString());
  
    setOrder((prevOrder) => ({
      ...prevOrder,
      cart_item: updatedCartItems
    }));

  };
  
  useEffect(() => {
    if (order?.currency) {
      fetchAllExchangeRates()
      .then(res => {
        const currency = res?.data?.find(c => (c?.abbreviation === order?.currency)) || res?.data?.find(c => (c?.abbreviation === "EUR"));
        setOrderCurrencySymbol(currency?.symbol)
      })
    } else {
      fetchAllExchangeRates()
      .then(res => {
        const currency = res?.data?.find(c => (c?.abbreviation === order?.currency)) || res?.data?.find(c => (c?.abbreviation === "EUR"));
        const currencytry = res?.data?.find(c => (c?.abbreviation === "TRY"));
        setOrderCurrencySymbol(currency?.symbol);
        setOrder({
          ...order,
          currency : "EUR",
          rate_usd_buying: currency?.rate_usd_buying,
          rate_usd_selling: currency?.rate_usd_selling,
          rate_usd_tr_buying: currencytry?.rate_usd_buying,
          rate_usd_tr_selling: currencytry?.rate_usd_selling,
        })
      })
    }
  }, [order?.currency])

  useEffect(() => {
    
    if(id !== "create") {
      listOrders({
        filter: { orderId: id },
        mode: "list_one"
      })
        .then((res) => {
          setReviewedCount(res?.data?.vendors?.filter((vendor) => vendor?.is_reviewed)?.length);
          form.setFieldsValue({
            status: res.data?.status,
          });

          if (res.data?.packages?.length) {
            setPackages(res.data?.packages);
            setCalculatedDesi(res.data?.packages.reduce(
              (previousValue, currentValue) => previousValue + currentValue?.desi,
              0.0
            ).toFixed(2))
            setCalculatedWeight(res.data?.packages.reduce(
              (previousValue, currentValue) => previousValue + parseFloat(currentValue?.weight),
              0.0
            ).toFixed(2))
          }
  
          setOrder({ 
            ...res.data,
            calculated_desi: res.data?.packages.reduce(
              (previousValue, currentValue) => previousValue + currentValue?.desi,
              0.0
            ).toFixed(2) || 0, 
            calculated_weight: res.data?.packages.reduce(
              (previousValue, currentValue) => previousValue + parseFloat(currentValue?.weight),
              0.0
            ).toFixed(2) || 0,
          });
  
        })
    } else {
      fetchCustomers({})
      .then((res) => {
        setCustomers(res.data)
      })
      .catch((err) => {
        console.log(err)
      });
    }

  }, [id, refresh]);

  useEffect(() => {
    const order_country_code = order?.address_information?.country;
    if(order_country_code){
      fetchCountries()
      .then((res) => {
        const country = res?.filter(((country) => country?.code === order_country_code))[0]
        setCountries(res);
      })
    }
  }, [order?.address_information?.country]);

  useEffect(() => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      calculated_desi: calculatedDesi,
      calculated_weight: calculatedWeight,
    }));
  }, [calculatedDesi, calculatedWeight]);

  const handleSubmit = async({ order }) => {

    setSaveLoading(true);

    let updatedOrder = {
      ...order,
      packages: packages || []
    }

    if (id !== "create") {
      fetchUpdate(
        order?._id,
        updatedOrder,
        needCalculatePrices
      )
        .then((res) => {
          setSaveLoading(false);
          setRefresh(r => !r);
          notification['success']({
            message: t('fields.edit_order.Order saved successfully'),
          });
        })
        .catch((err) => {
          setSaveLoading(false);
          notification['error']({
            message: t("fields.edit_order.Order couldn't saved successfully"),
          });

          setRefresh(r => !r);
        });
    } else {
      fetchCreate(
        updatedOrder,
        needCalculatePrices
      )
        .then((res) => {
          setSaveLoading(false);
          setOrder({ ...order, _id: res.data._id })
          notification['success']({
            message: t('fields.edit_order.Order created successfully'),
          });
          navigate("/logistics-orders");
        })
        .catch((err) => {
          setSaveLoading(false);
          notification['error']({
            message: t("fields.edit_order.Order couldn\'t created successfully"),
          });
        });
    }
  };

  const handleDesiChange = (change, index) => {

    setNeedCalculatePrices(true);

    let newMeasurement = {
      ...packages[index],
      ...change,
    }

    newMeasurement.desi = Math.max(
      newMeasurement.weight,
      newMeasurement.height *
      newMeasurement.length *
      newMeasurement.width /
      DESI_CONSTANT
    )

    let newPackages = packages.map((item, item_index) => {
      if (index === item_index) return newMeasurement;
      else return item;
    })

    setCalculatedDesi(newPackages.reduce(
      (previousValue, currentValue) => previousValue + currentValue?.desi,
      0.0
    ).toFixed(2))
    setCalculatedWeight(parseFloat(newPackages.reduce(
      (previousValue, currentValue) => previousValue + parseFloat(currentValue?.weight),
      0.0
    )))

    setPackages(newPackages);
  }

  const calculatePricesWithCurrency = (value) => {
    return !!value ? loncaRound(value * order?.rate_usd_selling) : 0;
  }

  const getAddressValidityValue = () => {
    if (order?.address_information?.is_valid_address) return "is_valid_address";
    if (order?.address_information?.is_valid_by_lonca_geomap_address) return "is_valid_by_lonca_geomap_address";
    if (order?.address_information?.is_valid_by_lonca_google_maps_address) return "is_valid_by_lonca_google_maps_address";
    if (order?.address_information?.is_valid_by_customer_support_address) return "is_valid_by_customer_support_address";
    return "";
  }

  const handleUploadInvoice = (invoiceLink) => {

		const extension = invoiceLink?.type === "application/pdf" ? "pdf" : "jpg";

		fetchUploadUrl(`invoice/${order?.unique_order_id.code}-english.${extension}`, invoiceLink?.type)
			.then(async response => {
				await fetch(response.data, {
					method: "PUT",
					body: invoiceLink,
				})
			
				let imageUrl = response.data.split('?')[0];
				setOrder({ ...order, invoice: imageUrl });
				notification['success']({
					message: 'Invoice uploaded to AWS successfully',
				});
				
			})
			.catch(err => {
				notification['error']({
					message: 'Invoice couldn\'t uploaded to AWS successfully',
				});
				console.log(err);
			})
	};

  const handleCreateShipment = () => {

    setShipmentCreationLoading(true);

    createShipment(order?._id)
      .then((res) => {
        setShipmentCreationLoading(false);
        setRefresh(r => !r);

        if (res?.error) {
          notification["error"]({
            message: res?.error,
            duration: 7.5
          });
        }

        notification[res?.trackingNumber === "success" ? "success" : "error"]({
          message: `${order?.real_cargo_company?.name || order?.cargo_company?.name} - ` + t(`fields.edit_order.shipment_creation_${res?.trackingNumber === "success" ? "success" : "fail"}`),
          duration: 3
        });

        notification[res?.label === "success" ? "success" : "error"]({
          message: `${order?.real_cargo_company?.name || order?.cargo_company?.name} - ` + t(`fields.edit_order.shipment_label_${res?.label === "success" ? "success" : "fail"}`),
          duration: 4.5
        });

        notification[res?.englishInvoice === "success" ? "success" : "error"]({
          message: `${order?.real_cargo_company?.name || order?.cargo_company?.name} - ` + t(`fields.edit_order.shipment_english_invoice_${res?.englishInvoice === "success" ? "success" : "fail"}`),
          duration: 6
        });

      })
      .catch((err) => {
        setShipmentCreationLoading(false);

        notification['error']({
          message: `${order?.cargo_company?.name} ${t("fields.edit_order.shipment_fail")}`,
        });
      });
  }

  return (
    <div className="logistics-order-container">

      <div className="order-details">

        <Form
          form={form}
          className="order-form"
          onFinish={() => handleSubmit({ order })}
          initialValues={{
            status: order?.status,
          }}
          layout="vertical"
        >
          <div className="header-save">
            {id === "create" ? 
                <Form.Item>
                  <Select
                    placeholder={"Please select logistics customer"}
                    value={order?.customer?._id}
                    onChange={async (e) => {
                      const selectedCustomer = customers?.find(customer => customer?._id?.toString() === e?.toString())

                      setOrder({
                        ...order,
                        customer: {
                          _id: selectedCustomer?._id,
                          firstName: selectedCustomer?.firstName,
                          lastName: selectedCustomer?.lastName,
                          email: selectedCustomer?.email,
                          phone_number: selectedCustomer?.phone_number,
                          unique_customer_id: selectedCustomer?.unique_customer_id,
                        },
                        address_information: selectedCustomer?.address_information,
                        billing_address_information: selectedCustomer?.billing_address_information,
                        status: "Received"
                      });
                    }}
                  >
                    {customers?.map(customer => (
                      <Option value={customer?._id}>{customer?.firstName} {customer?.lastName} - {customer?.unique_customer_id}</Option>
                    ))}
                  </Select>
                </Form.Item>              
            :
            <h2 className="order-header">{order?.customer?.firstName} {order?.customer?.lastName} <ExportOutlined onClick={() => window.open(`../logistics-customers/${order?.customer?._id}`)}/></h2>
            }
            <div>
              <Button className="button-margin" loading={saveLoading} type="primary" htmlType="submit" >
                {t('buttons.Save')}
              </Button>
            
              <Button type="secondary" onClick={() => navigate('../logistics-orders')}>
                {t('fields.edit_order.Cancel')}
              </Button>
            </div>
          </div>

          <div className="order-form-top">
            <div className="order-form-left">
              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.First Name')}>
                  <Input
                    value={order?.customer?.firstName}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Last Name')}>
                  <Input
                    value={order?.customer?.lastName}
                    disabled
                  />
                </Form.Item>
              </div>

              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Customer Email')}>
                  <Input
                    value={order?.customer?.email}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Customer Phone')}>
                  <Input
                    value={order?.customer?.phone_number}
                    disabled
                  />
                </Form.Item>
              </div>

              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Unique Customer ID')}>
                  <Input
                    value={order?.customer?.unique_customer_id}
                    disabled
                  />
                </Form.Item>

                <Form.Item label={t('fields.edit_order.Unique Order ID')}>
                  <Input
                    value={order?.unique_order_id}
                    disabled
                  />
                </Form.Item>
                
              </div>
              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Created Date')}>
                  <Input
                    value={loncaFormatDate(order?.createdAt)}
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Product Payment Date')}>
                  <DatePicker
                    placeholder={t('fields.edit_order.Select Date')}
                    showTime
                    value={order?.product_cost_payment_at ? moment(order?.product_cost_payment_at) : null}
                    onChange={(e) => {
                      setOrder({ ...order, product_cost_payment_at: new Date(e._d) })
                    }}
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Shipment Payment Date')}>
                  <DatePicker
                    placeholder={t('fields.edit_order.Select Date')}
                    showTime
                    value={order?.shipment_cost_payment_at ? moment(order?.shipment_cost_payment_at) : null}
                    onChange={(e) => {
                      setOrder({ ...order, shipment_cost_payment_at: new Date(e._d) })
                    }}
                  />
                </Form.Item>
              </div>

              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Net Volume')}>
                  <Input
                    value={`$${order?.full_volume} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.full_volume)}` : ""}`}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Product')}>
                  <Input
                    value={`$${order?.product_cost} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.product_cost)}` : ""}`}
                    disabled
                    className="input-width"
                  />
                </Form.Item>

              </div>

              <div className="inline_inputs">
                <Form.Item label={t('fields.edit_order.Local VAT') + (order?.local_vat > 0 ? ` (${order?.local_vat_rate * 100}%)`: "")}>
                  <Input
                    value={`$${order?.local_vat} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.local_vat)}` : ""}`}
                    disabled
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item label={t('fields.edit_order.Calculated Shipment Cost')}>
                  <Input
                    value={`$${order?.calculated_shipment_cost} ${orderCurrencySymbol !== "$" ? `- ${orderCurrencySymbol}${calculatePricesWithCurrency(order?.calculated_shipment_cost)}` : ""}`}
                    disabled
                    style={{ width: "100%" }}
                  />
                </Form.Item>

                <Form.Item label={t('fields.edit_order.Shipment Cost') + " ($)"}>
                  <Input
                    value={order?.shipment_cost || 0}
                    onChange={(e) => {
                      setOrder({
                        ...order,
                        shipment_cost: e.target.value,
                        shipment_cost_eur: loncaRound(parseFloat(e.target.value * order?.rate_usd_selling))
                      })
                      setNeedCalculatePrices(true);
                    }}
                  />
                </Form.Item>

                <Form.Item label={t('fields.edit_order.Shipment Cost') + ` (${orderCurrencySymbol})`}>
                  <Input
                    value={order?.shipment_cost_eur || 0}
                    onChange={(e) => {
                      setOrder({
                        ...order,
                        shipment_cost_eur: e.target.value,
                        shipment_cost: loncaRound(parseFloat(e.target.value / order?.rate_usd_selling))
                      })
                      setNeedCalculatePrices(true);
                    }}
                  />
                </Form.Item>

              </div>

              <Form.Item label={t('fields.edit_order.Paid Amount')}>
                <div className="address-box">
                  <div className="inline-inputs">

                    <Form.Item>
                      <div className="checkbox-container">
                        <label>
                        <input
                            type="checkbox"
                            checked={order?.product_cost_paid}
                            onChange={(e) => {
                              setOrder({
                                ...order,
                                product_cost_paid: e.target.checked,
                                product_cost_payment_at: e.target.checked ? new Date(Date.now()) : null
                              })
                            }}
                        />
                          Product Cost Paid
                        </label>
                      </div>
                    </Form.Item>

                    <Form.Item>
                      <div className="checkbox-container">
                        <label>
                        <input
                            type="checkbox"
                            checked={order?.shipment_cost_paid}
                            onChange={(e) => {
                              setOrder({
                                ...order,
                                shipment_cost_paid: e.target.checked,
                                shipment_cost_payment_at: e.target.checked ? new Date(Date.now()) : null
                              })
                            }}
                        />
                          Shipment Cost Paid
                        </label>
                      </div>
                    </Form.Item>

                  </div>
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Product Paid Amount Dollars') + " ($)"}>
                      <Input
                        value={`${order?.product_paid_amount}`}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            product_paid_amount: e.target.value,
                            total_paid_amount: loncaRound(parseFloat(e.target.value) + order?.shipment_paid_amount)
                          })
                        }}
                      />
                    </Form.Item>

                    {order?.currency &&

                      <Form.Item label={t('fields.edit_order.Product Paid Amount Local') + ` (${orderCurrencySymbol})`}>
                        <Input
                          value={`${loncaRound(order?.product_paid_amount * order?.rate_usd_selling)}`}
                          disabled
                        />
                      </Form.Item>
                    }
                  </div>
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Shipment Paid Amount Dollars') + " ($)"}>
                      <Input
                        value={`${order?.shipment_paid_amount}`}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            shipment_paid_amount: e.target.value,
                            total_paid_amount: loncaRound(parseFloat(e.target.value) + order?.product_paid_amount)
                          })
                        }}
                      />
                    </Form.Item>

                    {order?.currency &&

                      <Form.Item label={t('fields.edit_order.Shipment Paid Amount Local') + ` (${orderCurrencySymbol})`}>
                        <Input
                          value={`${loncaRound(order?.shipment_paid_amount * order?.rate_usd_selling)}`}
                          disabled
                        />
                      </Form.Item>
                    }
                  </div>
                  <div className="inline_inputs">
                    <Form.Item label={t('fields.edit_order.Rate Usd Selling')}>
                      <Input
                        value={`${orderCurrencySymbol} ${order?.rate_usd_selling}`}
                        disabled
                      />
                    </Form.Item>

                  </div>
                  
                </div> 
              </Form.Item>

      
              <Form.Item label={t('fields.edit_order.Measurements')}>
                <div className="address-box">
                  {packages?.map((package_item, index) => (
                    <>
                      <Form.Item label={`${t('fields.edit_order.Package')} ${index + 1}`} >
                        <div className="address-box">
                          <div className="inline-inputs">
                            <Form.Item label={t('fields.edit_order.Length')}>
                              <Input
                                prefix="cm"
                                value={package_item?.length}
                                onChange={(e) => {
                                  handleDesiChange({ length: e.target.value }, index);
                                }}
                              />
                            </Form.Item>
                            <Form.Item label={t('fields.edit_order.Width')}>
                              <Input
                                prefix="cm"
                                value={package_item?.width}
                                onChange={(e) => {
                                  handleDesiChange({ width: e.target.value }, index);
                                }}
                              />
                            </Form.Item>
                          </div>
                          <div className="inline-inputs">
                            <Form.Item label={t('fields.edit_order.Height')}>
                              <Input
                                prefix="cm"
                                value={package_item?.height}
                                onChange={(e) => {
                                  handleDesiChange({ height: e.target.value }, index);
                                }}
                              />
                            </Form.Item>
                            <Form.Item label={t('fields.edit_order.Weight')}>
                              <Input
                                prefix="kg"
                                value={package_item?.weight}
                                onChange={(e) => {
                                  handleDesiChange({ weight: e.target.value }, index);
                                }}
                              />
                            </Form.Item>
                          </div>
                          <Button
                            type="primary"
                            className="button-margin calculate-button"
                            onClick={() => {
                              const newPackages = packages.filter((item, inner_index) => {
                                return inner_index !== index
                              });
                              setPackages(newPackages)
                              setCalculatedDesi(newPackages.reduce((prev, curr) => prev + curr?.desi, 0.0).toFixed(2))
                              setCalculatedWeight(parseInt(newPackages.reduce((prev, curr) => prev + parseFloat(curr?.weight), 0.0)));
                              setNeedCalculatePrices(true);
                            }}
                          >
                            {t('fields.edit_order.Remove')}
                          </Button>
                        </div>
                      </Form.Item>
                    </>
                  ))}

                  <Button
                    type="primary"
                    className="button-margin calculate-button"
                    disabled={order?.vendors?.find(item => !item?.is_reviewed)}
                    onClick={() => {
                      setPackages(packages => [...packages, { width: 0, height: 0, weight: 0, length: 0, desi: 0 , date: new Date()}]);
                      setNeedCalculatePrices(true);
                    }}
                  >
                    {t('fields.edit_order.Add Package')}
                  </Button>

                  <div className="inline_inputs_edit_order">
                    <Form.Item label={t('fields.edit_order.Calculated Desi')}>
                      <Input
                        value={calculatedDesi}
                        disabled
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Calculated Weight')}>
                      <Input
                        value={calculatedWeight}
                        disabled
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Reviewed Count')}>
                      <Input
                        value={reviewedCount}
                        disabled
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form.Item>
              <Form.Item label={t('fields.edit_order.Shipping Address')}>
                <div className="address-box">
                  <div className="inline-inputs">
                    
                    <Form.Item label={t('Valid Address')}>
                      <Input
                        value={order?.address_information?.valid_formatted_address}
                        onChange={(e) => setOrder({
                          ...order,
                          address_information: {
                            ...order?.address_information,
                            valid_formatted_address: e.target.value
                          }
                        })}
                        disabled={!getAddressValidityValue()?.includes("is_valid")}
                      />
                    </Form.Item>
                    
                    <Form.Item label={t('fields.edit_order.Address')}>
                      <Input
                        value={order?.address_information?.address}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              address: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.apartment_suite')}>
                      <Input
                        value={order?.address_information?.apartment_suite}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              apartment_suite: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>
                    
                    <Form.Item label={t('fields.edit_order.City')}>
                      <Input
                        value={order?.address_information?.city}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              city: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.State')}>
                      <Input
                        value={order?.address_information?.state_or_province}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              state_or_province: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        value={order?.address_information?.postal_code}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              postal_code: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Country')}>
                      <Select
                        showSearch
                        value={order?.address_information?.country}
                        optionFilterProp="children"
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              country: e
                            }
                          });
                        }}>
                        {countries?.map((country) =>
                            <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                      </Select>
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Phone Number')}>
                      <Input
                        value={order?.address_information?.phone_number}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              phone_number: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.address_validity')}>
                      <Select
                        value={getAddressValidityValue()}
                        onChange={(e) => {
                          const fields = ["is_valid_address", "is_valid_by_lonca_geomap_address", "is_valid_by_lonca_google_maps_address", "is_valid_by_customer_support_address"];
                          const changedField = e;
                          const otherFields = fields?.filter(f => f !== changedField);
                          const otherFieldsMapping = otherFields?.reduce((acc, crr) => {
                            acc[crr] = false;
                            return acc;
                          }, {})

                          setOrder({
                            ...order,
                            address_information: {
                              ...order?.address_information,
                              [changedField]: true,
                              ...otherFieldsMapping
                            }
                          });
                        }}
                      >
                        <Option value=""></Option>
                        <Option value="is_valid_address">{t('fields.edit_order.is_valid_address')}</Option>
                        <Option value="is_valid_by_lonca_geomap_address">{t('fields.edit_order.is_valid_by_lonca_geomap_address')}</Option>
                        <Option value="is_valid_by_lonca_google_maps_address">{t('fields.edit_order.is_valid_by_lonca_google_maps_address')}</Option>
                        <Option value="is_valid_by_customer_support_address">{t('fields.edit_order.is_valid_by_customer_support_address')}</Option>
                      </Select>
                    </Form.Item>
                    
                  </div>

                </div>
              </Form.Item>

              <Form.Item label={t('fields.edit_order.Billing Address')}>
                <div className="address-box">
                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Billing Name')}>
                      <Input
                        value={order?.billing_address_information?.billing_name}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              billing_name: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Company Type')}>
                      <Input
                        value={order?.billing_address_information?.company_type}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              company_type: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="inline_inputs">
                    <Form.Item label={t('fields.edit_order.VAT Number')}>
                      <Input
                        value={order?.billing_address_information?.corporate_identity_number}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              corporate_identity_number: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>

                    <Form.Item label={t('fields.edit_order.Tax Office')}>
                      <Input
                        value={order?.billing_address_information?.tax_office}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              tax_office: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="inline-inputs">
                    <Form.Item label={t('fields.edit_order.Address')}>
                      <Input
                        value={order?.billing_address_information?.address}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              address: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.City')}>
                      <Input
                        value={order?.billing_address_information?.city}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              city: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className="inline_inputs">
                    <Form.Item label={t('fields.edit_order.Postal Code')}>
                      <Input
                        value={order?.billing_address_information?.postal_code}
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              postal_code: e.target.value
                            }
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item label={t('fields.edit_order.Country')}>
                      <Select
                        showSearch
                        value={order?.billing_address_information?.country}
                        optionFilterProp="children"
                        onChange={(e) => {
                          setOrder({
                            ...order,
                            billing_address_information: {
                              ...order?.billing_address_information,
                              country: e
                            }
                          });
                        }}>
                        {countries?.map((country) =>
                            <Option value={country?.code}>{country?.code + " - " + country?.name}</Option>)}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="inline-inputs">
                    <div className="checkbox-container">
                      <label>
                      <input
                          type="checkbox"
                          checked={order?.billing_address_information?.isVatValid}
                          onChange={(e) => {
                            setOrder({
                              ...order,
                              billing_address_information: {
                                ...order?.billing_address_information,
                                isVatValid: e.target.checked
                              }
                            })
                            setNeedCalculatePrices(true);
                          }}
                      />
                        Is Valid VAT
                      </label>
                    </div>
                  </div>
                </div>
              </Form.Item>
            </div>
            <div className="order-form-right">
              <div className="status-save">

                <Form.Item name="status" label={t('fields.edit_order.Status')}>
                  <></>
                  <Select
                    value={order?.status}
                    onChange={async (e) => {                   
                      setOrder({
                        ...order,
                        status: e
                      });
                    }}
                  >
                    {orderStatuses?.map(status => (
                      <Option value={status}>{t(`fields.order_status.${status}`)}</Option>
                    ))}
                  </Select>
                </Form.Item>

              </div>
              
              <Form.Item>
                <div className="address-box">
                  <Form.Item label={t('fields.edit_order.Admin Notes')}>
                    <TextArea
                      value={order?.notes}
                      onChange={(e) => {
                        setOrder({
                          ...order,
                          notes: e.target.value
                        })
                      }}
                      placeholder={t('fields.edit_order.Admin Notes')}
                    />
                  </Form.Item>
                </div>
              </Form.Item> 

              <div className="address-box">

                <Form.Item label={t('fields.edit_order.Invoice No')}>
                  <Input
                    value={order?.parasut_invoice_no}
                    onChange={(e) => {
                      setOrder({
                        ...order,
                        parasut_invoice_no: e.target.value
                      });
                      setSaved(false);
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <div className="english-invoice-buttons">
                    <Button 
                      onClick={() => {
                        fetchCreateEnglishInvoice({ order })
                          .then(() => {
                            notification['success']({
                              message: t('fields.edit_order.Invoice created successfully'),
                            });
                            setRefresh(r => !r);
                          })
                          .catch(() => {
                            notification['error']({
                              message: t("fields.edit_order.Invoice couldn't created successfully"),
                            });
                            setRefresh(r => !r);
                          })
                      }}
                    >
                      {t('fields.edit_order.Create English Invoice')}
                    </Button>

                    {order?.invoice ? 
                      <label class="custom-file-upload"
                        onClick={() => {
                          window.open(order?.invoice)
                        }}
                      >
                        <DownloadOutlined/>
                        Download Invoice
                      </label>
                      
                      :
                      <label class="custom-file-upload">
                        <input type="file" onChange={async (e) => {
                          handleUploadInvoice(e.target.files[0]);
                        }}/>
                        <UploadOutlined />
                        {"  Upload Invoice"}
                      </label>
                    }

                    
                
                  </div>
                </Form.Item>
              </div>

              <Form.Item>
                  <div className="address-box">
                    <ButtonGroup>
                      {(order?.status === "Ready") && 
                        <Button
                          type="secondary"
                          className="button-margin"
                          onClick={handleCreateShipment}
                          loading={shipmentCreationLoading}
                        >
                          {t('fields.edit_order.Create Shipment')} - {order?.real_cargo_company?.name || order?.cargo_company?.name}
                        </Button> 
                      }
                      {
                        (order?.shipment_label) &&
                        <a href={order?.shipment_label} target='_blank' rel='noopener noreferrer'>
                          <Button
                            type="secondary"
                          >
                            {t('fields.edit_order.download_label')}
                          </Button>
                        </a>
                      }
                    </ButtonGroup>
                  </div>
              </Form.Item>
            </div>
          </div>

          <div className="add-new-product">
            <Button onClick={() => setIsAddingVendor(true)}>Add Vendor</Button>
          </div>

          {order?.vendors?.length || isAddingVendor ? (
            <div className="cart-form-top">
              <div className="cart-form-top-bar">
                <p className="cart-form-number">{t("fields.edit_order.# of vendors")}: {order?.vendors?.length}</p>
              </div>

              {isAddingVendor && (
                <div className="cart_item_order">
                  <div className="cart_item_info">
                    <div className="inline_inputs"> 
                      <FormItem label={t("fields.edit_order.Vendor")}> 
                        <Input
                          value={newVendor?.vendor_name || ''}
                          onChange={(e) => setNewVendor({ ...newVendor, vendor_name: e.target.value })}
                        />
                      </FormItem>

                      <Form.Item label={t("fields.edit_order.Notes")}>
                        <Input
                          value={newVendor?.vendor_note}
                          onChange={(e) => setNewVendor({ ...newVendor, vendor_note: e.target.value })}
                        />
                      </Form.Item>

                      <Form.Item label={t("fields.edit_order.Price") + " ($)"}>
                        <Input
                          value={newVendor?.product_cost || 0}
                          onChange={(e) => {
                            setNewVendor({ 
                              ...newVendor, 
                              product_cost: e.target.value,
                              product_cost_eur: loncaRound(parseFloat(e.target.value) * order?.rate_usd_selling)  
                            })
                          }}
                        />
                      </Form.Item>

                      <Form.Item label={t("fields.edit_order.Price") + ` (${orderCurrencySymbol})`}>
                        <Input
                          value={newVendor?.product_cost_eur || 0}
                          onChange={(e) => {
                            setNewVendor({ 
                              ...newVendor, 
                              product_cost_eur: e.target.value,
                              product_cost: loncaRound(parseFloat(e.target.value) / order?.rate_usd_selling)  
                            })
                          }}
                        />
                      </Form.Item>

                    </div>

                    <div> 

                      <Button onClick={handleAddVendor}>Save New Vendor</Button>
                      <Button 
                        onClick={() => {
                          setNewVendor({});
                          setIsAddingVendor(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </div>

                  </div>
                </div>
              )}

              {order?.vendors?.map((item, index) => (
                <div className="cart_item_order" key={item._id || index}>

                  <div className="cart_item_info">
                    <div className="inline_four_inputs_order">
                      <FormItem label={t("fields.edit_order.Vendor")}>
                        <Input
                          value={item?.vendor_name}
                          onChange={(e) => handleVendorChange(index, 'vendor_name', e.target.value)}
                        />
                      </FormItem>

                      <Form.Item label={t("fields.edit_order.Notes")}>
                        <Input
                          value={item?.vendor_note}
                          onChange={(e) => handleVendorChange(index, 'vendor_note', e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item label={t("fields.edit_order.Price") + " ($)"}>
                        <Input
                          value={item?.product_cost || 0}
                          onChange={(e) => {
                            handleVendorChange(index, 'product_cost', e.target.value);
                            handleVendorChange(index, 'product_cost_eur', loncaRound(parseFloat(e.target.value * order?.rate_usd_selling)));
                            setNeedCalculatePrices(true);
                          }}
                        />
                      </Form.Item>

                      <Form.Item label={t("fields.edit_order.Price") + ` (${orderCurrencySymbol})`}>
                        <Input
                          value={item?.product_cost_eur || 0}
                          onChange={(e) => {
                            handleVendorChange(index, 'product_cost_eur', e.target.value);
                            handleVendorChange(index, 'product_cost', loncaRound(parseFloat(e.target.value / order?.rate_usd_selling)));
                            setNeedCalculatePrices(true);
                          }}
                        />
                      </Form.Item>

                      <Form.Item label={t('fields.edit_order.Reviewed')}>
                        <Checkbox
                          checked={item.is_reviewed}
                          onChange={(e) => handleVendorChange(index, 'is_reviewed', e.target.checked)}
                        />
                      </Form.Item>

                      <Form.Item label={t('fields.order_status.Packaged')}>
                        <Checkbox
                          checked={item.is_packaged}
                          onChange={(e) => handleVendorChange(index, 'is_packaged', e.target.checked)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="close-price">
                    <Button
                      icon={<CloseOutlined />}
                      onClick={() => handleDeleteVendorItem({ vendorId: item?._id })}
                    />
                  </div>
                </div>
              ))}

              
            </div>
          ) : null} 

          {order?.vendors?.length ?
            <div className="add-new-product">
              <Button onClick={() => setIsAddingProduct(true)}>Add Product</Button>
            </div>
            : null
          }

          {order?.vendors?.length && (order?.cart_item?.length || isAddingProduct) ? (
            <div className="cart-form-top">
              <div className="cart-form-top-bar">
                <p className="cart-form-number">{t("fields.edit_order.# of items")}: {order?.cart_item?.length}</p>
              </div>

              {isAddingProduct && (
                <div className="cart_item_order">
                  <div className="cart_item_info">
                    <div className="inline_three_inputs"> 
                      <FormItem label={t("fields.product.Product Name") + " (EN)"}> 
                        <Input
                          value={newProduct?.product_name || ''}
                          onChange={(e) => 
                            setNewProduct({ ...newProduct, product_name: e.target.value, names: { ...newProduct?.names, en: e.target.value } })
                          }
                        />
                      </FormItem>

                      <FormItem label={t("fields.product.Product Name") + " (TR)"}> 
                        <Input
                          value={newProduct?.product_name_tr || ''}
                          onChange={(e) => 
                            setNewProduct({ ...newProduct, product_name_tr: e.target.value, names: { ...newProduct?.names, tr: e.target.value } })
                          }
                        />
                      </FormItem>

                      <Form.Item label={t("fields.edit_order.Vendor")}>
                        <Select
                          placeholder={"Please select related vendor"}
                          value={newProduct?.vendor}
                          onChange={async (e) => {
                            const selectedVendor = order?.vendors?.find(vendor => vendor?._id?.toString() === e?.toString())
                            setNewProduct({
                              ...newProduct,
                              vendor: selectedVendor?._id,
                              vendor_name: selectedVendor?.vendor_name,
                            });

                          }}
                        >
                          {order?.vendors?.map(vendor => (
                            <Option value={vendor?._id}>{vendor?.vendor_name}</Option>
                          ))}
                        </Select>
                      </Form.Item> 

                      <Form.Item label={t("fields.edit_order.Price") + " ($)"}>
                        <Input
                          value={newProduct?.price || 0}
                          // onChange={(e) => {
                          //   setNewProduct({ ...newProduct, price: e.target.value, price_eur: loncaRound(parseFloat(e.target.value) * order?.rate_usd_selling) })
                          // }}
                          disabled
                        />
                      </Form.Item>

                      <Form.Item label={t("fields.edit_order.Price") + ` (₺)`}>
                        <Input
                          value={newProduct?.price_try || 0}
                          onChange={(e) => {
                            setNewProduct({ ...newProduct, price_try: e.target.value, price: loncaRound(parseFloat(e.target.value) / order?.rate_usd_tr_selling), price_eur: loncaRound(parseFloat(e.target.value) * order?.rate_usd_selling / order?.rate_usd_tr_selling)})
                          }}
                        />
                      </Form.Item> 

                      <Form.Item label={t("fields.edit_order.Price") + ` (${orderCurrencySymbol})`}>
                        <Input
                          value={newProduct?.price_eur || 0}
                          onChange={(e) => {
                            setNewProduct({ ...newProduct, price_eur: e.target.value, price: loncaRound(parseFloat(e.target.value) / order?.rate_usd_selling), price_try: loncaRound(parseFloat(e.target.value) * order?.rate_usd_tr_selling / order?.rate_usd_selling) })
                          }}
                        />
                      </Form.Item> 

                      <Form.Item label={t("fields.edit_order.Quantity_logistics")}>
                        <Input
                          value={newProduct?.quantity || 0}
                          onChange={(e) => {
                            setNewProduct({ ...newProduct, quantity: parseInt(e.target.value) })
                          }}
                        />
                      </Form.Item>

                      <Form.Item label={t("fields.edit_order.Total Price")}>
                        <Input
                          value={`$${loncaRound(newProduct?.quantity * newProduct?.price)} - ${orderCurrencySymbol}${calculatePricesWithCurrency(newProduct?.quantity * newProduct?.price)}`}
                          disabled
                        />
                      </Form.Item>


                      <Form.Item label={t("fields.product.Product Type")}>
                        <Select
                          placeholder={"Please select product type"}
                          value={newProduct?.product_type?._id}
                          onChange={async (e) => {
                            const selectedType = allProductTypes?.find(type => type?._id?.toString() === e?.toString())
                            setNewProduct({
                              ...newProduct,
                              product_type: {
                                _id: selectedType?._id,
                                vat: selectedType?.vat,
                                name: selectedType?.name,
                                name_tr: selectedType?.name_tr,
                                names: {en: selectedType?.names?.en, tr: selectedType?.names?.tr},
                                fabric: selectedType?.fabric,
                                gtip_code: selectedType?.gtip_code,
                              }
                            });

                          }}
                        >
                          {allProductTypes?.slice()?.sort((a, b) => a?.names?.en.localeCompare(b?.names?.en))
                            .map((type) => (
                                <Select.Option value={type?._id}>
                                  {type?.names?.en}
                                </Select.Option>
                            ))}
                        </Select>
                      </Form.Item> 

                      <Form.Item label={t("fields.edit_order.Notes")}>
                        <Input
                          value={newProduct?.product_note}
                          onChange={(e) => setNewProduct({ ...newProduct, product_note: e.target.value })}
                        />
                      </Form.Item>

                    </div>

                    <div> 

                      <Button onClick={handleAddProduct}>Save New Product</Button>
                      <Button 
                        onClick={() => {
                          setNewProduct({});
                          setIsAddingProduct(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </div>

                  </div>
                </div>
              )}

              {order?.cart_item?.map((item, index) => (
                <div className="cart_item_order" key={item._id || index}>

                  <div className="cart_item_info">
                    <div className="inline_four_inputs_order">
                      <FormItem label={t("fields.product.Product Name") + " (EN)"}>
                        <Input
                          value={item?.product_name}
                          onChange={(e) => handleCartItemChange(index, 'product_name', e.target.value)}
                        />
                      </FormItem>

                      <FormItem label={t("fields.product.Product Name") + " (TR)"}>
                        <Input
                          value={item?.product_name_tr}
                          onChange={(e) => handleCartItemChange(index, 'product_name_tr', e.target.value)}
                        />
                      </FormItem>

                      <FormItem label={t("fields.edit_order.Vendor")}>
                        <Input
                          value={item?.vendor_name}
                          disabled
                        />
                      </FormItem>

                      <Form.Item label={t("fields.edit_order.Price") + " ($)"}>
                        <Input
                          value={item?.price || 0}
                          // onChange={(e) => {
                          //   handleCartItemChange(index, 'price', e.target.value)
                          //   handleCartItemChange(index, 'price_eur', loncaRound(parseFloat(e.target.value * order?.rate_usd_selling)))
                          // }}
                          disabled
                        />
                      </Form.Item>

                      <Form.Item label={t("fields.edit_order.Price") + ` (₺)`}>
                        <Input
                          value={item?.price_try || 0}
                          onChange={(e) => {
                            handleCartItemChange(index, 'price_try', e.target.value)
                            handleCartItemChange(index, 'price', loncaRound(parseFloat(e.target.value / order?.rate_usd_tr_selling)))
                            handleCartItemChange(index, 'price_eur', loncaRound(parseFloat(e.target.value) * order?.rate_usd_selling / order?.rate_usd_tr_selling))
                          }}
                        />
                      </Form.Item>

                      <Form.Item label={t("fields.edit_order.Price") + ` (${orderCurrencySymbol})`}>
                        <Input
                          value={item?.price_eur || 0}
                          onChange={(e) => {
                            handleCartItemChange(index, 'price_eur', e.target.value)
                            handleCartItemChange(index, 'price', loncaRound(parseFloat(e.target.value / order?.rate_usd_selling)))
                            handleCartItemChange(index, 'price_try', loncaRound(parseFloat(e.target.value * order?.rate_usd_tr_selling / order?.rate_usd_selling)))
                          }}
                        />
                      </Form.Item>

                      <Form.Item label={t("fields.edit_order.Quantity_logistics")}>
                        <Input
                          value={item?.quantity || 0}
                          onChange={(e) => {handleCartItemChange(index, 'quantity', parseFloat(e.target.value))}}
                        />
                      </Form.Item>

                      <Form.Item label={t("fields.edit_order.Total Price")}>
                        <Input
                          value={`$${loncaRound(item?.quantity * item?.price)} - ${orderCurrencySymbol}${calculatePricesWithCurrency(item?.quantity * item?.price)}`}
                          disabled
                        />
                      </Form.Item>

                      <FormItem label={t("fields.product.Product Type")}>
                        <Input
                          value={item?.product_type?.name}
                          disabled
                        />
                      </FormItem>


                      <Form.Item label={t("fields.edit_order.Notes")}>
                        <Input
                          value={item?.product_note}
                          onChange={(e) => handleCartItemChange(index, 'product_note', e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="close-price">
                    <Button
                      icon={<CloseOutlined />}
                      onClick={() => handleDeleteCartItem({ cartItemId: item?._id })}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : null} 
        </Form>
      </div >
    </div >
  )
};

export default EditLogisticsOrder;