import { useState } from 'react';
import { getDate, loncaRound } from '../../helpers';
import { fetchDelete } from '../../redux/logisticsOrderSlice';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
  
import './LogisticsOrder.css';

import { Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

function LogisticsOrder({ item }) {
  const { t } = useTranslation('translation');
  const [visible, setVisible] = useState(true);
  const [selected, setSelected] = useState(false);
  const { rates } = useSelector((state) => state.exchangeRates);
  const currency = rates?.find(c => (c?.abbreviation === item?.currency))?.symbol

  const calculatePricesWithCurrency = (value) => {
    return !!value ? loncaRound(value * item?.rate_usd_selling) : 0;
  }

  const handleDelete = (e) => {
    e.preventDefault();
    fetchDelete(item._id)
      .then(() => {
        notification['success']({
          message: 'Deleting successful',
        });
        setVisible(false);
      })
      .catch((err) => {
        notification['error']({
          message: err.response.data.message,
        });
      });
  };
  if (!visible) return null;

  return (
    <div className="list-logistics-order">
      <Link 
        className="list-logistics-order-item"
        to={`../logistics-orders/${item?._id}`}
      >
        <div className="list-order-item-check" onClick={(e) => e.stopPropagation()}>
          <input
            value={selected}
            onChange={() =>  {
              const { checked } = event.target;
              setSelected((state) => !state)}
            }
            type="checkbox"
          />
        </div>
        <div className="list-order-item-fname">
          {item.customer?.firstName || 'User Not Found'}
        </div>
        <div className="list-order-item-lname">
          {item.customer?.lastName || 'User Not Found'}
        </div>

        <div className="list-order-item-lname">
          {item.customer?.unique_customer_id || 'User Not Found'}
        </div>

        <div className="list-order-item-status">{t(`fields.order_status.${item?.status}`)}</div>
        <div className="list-order-item-cost">{`${currency}${calculatePricesWithCurrency(item?.full_volume)}`} </div>

        <div className="list-order-item-checkout">{item?.createdAt ? getDate(item?.createdAt) : "-"}</div>
        <div className="list-order-item-payment">{item?.shipment_cost_payment_at ? getDate(item?.shipment_cost_payment_at) : "-"}</div>
        <div className="list-order-item-completed">{item?.delivery_date ? getDate(item?.delivery_date) : "-"}</div>

        <div id="trash" className={!selected ? 'visibility-hidden' : null}>
          <Button onClick={(e) => handleDelete(e)} icon={<DeleteOutlined />} />
        </div>
      </Link>
    </div>
  );
}

export default LogisticsOrder;
